import {
    Button,
    Checkbox,
    createStyles,
    FormControlLabel,
    Grid,
    Icon,
    makeStyles,
    TextField, Typography,
    withWidth
} from '@material-ui/core'
import Box from '@material-ui/core/Box'
import {useFormik} from 'formik'
import React, {useEffect, useState} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {createPost, getCaptcha} from '../../../redux/actions/guestbook'
import {GUESTBOOK_SET_POST_RESULT, GUESTBOOK_SET_POSTED} from '../../../redux/types'
import {IState} from '../../../utils/models/IState'

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            marginBottom: 60
        },
        image: {
            maxWidth: 96,
            height: 96,
            width: '100%',
            borderRadius: '50%',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            position: 'relative',
            overflow: 'hidden',
            cursor: 'pointer',
            '&:hover':{
                '& div':{
                    display: 'flex'
                }
            }
        },
        refresh:{
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            width: '100%',
            background: 'rgba(0,0,0, 0.4)',
            color: '#fff',
            display: 'none',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-around',
            fontWeight: 500,
        },
        messageContainer:{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-evenly'
        },
        message: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            '&.success': {
                color: '#21B777',
                fontSize: 32,
                textAlign: 'center',
                '& .material-icons':{
                    fontSize: 32
                },
            },
            '&.error': {
                color: '#ff5252'
            }
        }
    }),
)

export const GuestbookForm = ({width}) => {
    const classes = useStyles()
    const {captchaImg, posted, postResult} = useSelector((state: IState) => state.guestbook, shallowEqual)
    const dispatch = useDispatch()
    const [disabled, setDisabled] = useState(true)

    useEffect(() => {
        dispatch(getCaptcha())
    }, [])

    const handleChange = (e) => {
        formik.setFieldValue(e.target.name, e.target.value)
    }

    const handleRefreshClick = () =>{
        dispatch(getCaptcha())
    }

    const handleWriteMorePost = () => {
        dispatch({type: GUESTBOOK_SET_POSTED, payload: false})
        dispatch({type: GUESTBOOK_SET_POST_RESULT, payload: []})
        formik.setValues({
            name: formik.values.name,
            email: formik.values.email,
            city: formik.values.city,
            url: formik.values.url,
            captcha: '',
            message: '',
        })
    }

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            city: '',
            url: '',
            message: '',
            captcha: ''
        },
        onSubmit: (values) => {
            dispatch(createPost(values))
        },
    })

    const spacing = {
        'xs': 1,
        'sm': 2
    }

    return (
        <form onSubmit={formik.handleSubmit} className={classes.root}>
            <Grid container spacing={spacing[width] || 3}>
                {!posted ?
                    <>
                        <Grid item xs={6}>
                            <TextField
                                variant={'outlined'}
                                fullWidth
                                type={'text'}
                                name={'name'}
                                value={formik.values.name}
                                placeholder={'Имя'}
                                label={'Ваше имя'}
                                size={'small'}
                                required={true}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                variant={'outlined'}
                                fullWidth
                                type={'text'}
                                name={'email'}
                                value={formik.values.email}
                                placeholder={'E-Mail'}
                                label={'E-Mail'}
                                size={'small'}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                variant={'outlined'}
                                fullWidth
                                type={'text'}
                                name={'city'}
                                value={formik.values.city}
                                placeholder={'Город'}
                                label={'Город'}
                                size={'small'}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                variant={'outlined'}
                                fullWidth
                                type={'text'}
                                name={'url'}
                                value={formik.values.url}
                                placeholder={'Url'}
                                label={'Url'}
                                size={'small'}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                multiline
                                rows={4}
                                variant={'outlined'}
                                fullWidth
                                type={'text'}
                                name={'message'}
                                value={formik.values.message}
                                placeholder={'Введите сообщение'}
                                label={'Сообщение'}
                                size={'small'}
                                required
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={8} lg={9}>
                            <Grid container spacing={2} style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'flex-start'
                            }}>
                                <Grid item xs={3} sm={3} md={4} lg={3}>
                                    {captchaImg &&
                                    <Box className={classes.image}
                                         style={{backgroundImage: `url(${process.env.REACT_APP_API_URL}/files/${captchaImg})`}}>
                                        <Box onClick={handleRefreshClick} className={classes.refresh}><p>Не узнаю :(</p>
                                        </Box>
                                    </Box>
                                    }
                                </Grid>
                                <Grid item xs={9} sm={9} md={8} lg={9}>
                                    <TextField
                                        variant={'outlined'}
                                        fullWidth
                                        type={'text'}
                                        name={'captcha'}
                                        value={formik.values.captcha}
                                        placeholder={'Введите имя музыканта с картинки'}
                                        label={'Имя музыканта'}
                                        size={'small'}
                                        required
                                        onChange={handleChange}
                                    />
                                    <Grid item xs={12}>
                                        <FormControlLabel
                                          style={{marginTop: 10, alignItems: 'flex-start'}}
                                          label={<Typography style={{fontSize: 13, marginTop: 2}}>Cогласен на обработку персональных данных в соответствии с <a href={'/files/Музыка_ПОЛИТИКА_КОНФИДЕНЦИАЛЬНОСТИ.pdf'} target={'_blank'} rel={'noreferrer'}>политикой конфиденциальности</a></Typography>}
                                          control={<Checkbox style={{padding: 0, margin: '0 7px'}} color={'primary'} onClick={() => setDisabled(prevState => !prevState)} />}
                                        />
                                    </Grid>
                                    {
                                        (!posted && postResult.length > 0) &&
                                        <p className={`${classes.message} error`}><Icon>error_outline</Icon>&nbsp;Не
                                            угадали :(</p>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3}
                              style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  alignItems: 'flex-start',
                                  justifyContent: 'flex-end',
                              }}>
                            <Button style={{marginTop: 0}} fullWidth type={'submit'} variant={'contained'} size={'large'}
                                    color={'primary'} disableElevation disabled={disabled}>Отправить</Button>
                        </Grid>
                    </>
                    :
                    <Grid item container xs={12} spacing={0}>
                        <Grid item xs={12} className={classes.messageContainer}>
                            <Icon style={{fontSize: 64}} className={`${classes.message} success`}>check_circle</Icon>
                        </Grid>
                        <Grid item xs={12} className={classes.messageContainer}>
                            <p style={{margin:10}} className={`${classes.message} success`}>Спасибо за отзыв!</p>
                        </Grid>
                        <Grid item xs={12} className={classes.messageContainer}>
                            <Button variant={'text'} color={'primary'} onClick={handleWriteMorePost}>Написать еще</Button>
                        </Grid>
                    </Grid>
                }
            </Grid>
        </form>
    )
}

export default withWidth()(GuestbookForm)