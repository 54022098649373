import {createStyles, Grid, makeStyles} from '@material-ui/core'
import React, {useEffect} from 'react'
import {useLocation} from 'react-router-dom'
import ym from 'react-yandex-metrika'
import ContactBlock from '../components/basics/ContactBlock'
import Layout from '../components/layout/Layout'
import {Helmet} from 'react-helmet-async'
import ContainerTitled from '../components/misc/ContainerTitled'
import Poster from '../components/misc/Poster'

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            fontWeight: 500,
            '& .bordered': {
                background: 'url(/assets/images/misc/border-h.svg) repeat-x left bottom',
                backgroundSize: '16px',
                margin: '20px 0'
            },
            '& .centered': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-around'
            },
            '&:first-child': {
                paddingTop: 0
            },
            '& h3': {
                fontSize: 18,
                fontFamily: 'Zantroke',
                fontWeight: 500,
                margin: '0 0 30px 0'
            },
            '& p':{
                lineHeight: '1.8rem'
            }
        }
    }),
)

export const AboutPage = () => {
    const {pathname} = useLocation()
    const classes = useStyles()

    useEffect(() => {
        ym('hit', pathname)
    }, [])

    return (
        <Layout title={'О клубе'} variant={'center'} additionalComponents={[<ContactBlock key={'contact_block'}/>]}>
            <Helmet>
                <title>Колесо: О клубе</title>
            </Helmet>
            <ContainerTitled>
                <Grid container spacing={2} className={classes.root}>
                    <Grid item xs={12}>
                        <h3>Первый музыкальный</h3>
                        <p>"Колесо" существует с 2005 г. Это первый в Архангельске музыкальный клуб. </p>
                        <Poster
                            appImage={true}
                            imageSrc={'assets/images/about/koleso_about_1.png'}
                        />
                    </Grid>
                    <Grid item xs={12} className='bordered'/>
                    <Grid item xs={12} md={6}>
                        <h3>“Колесо” - клуб-легенда</h3>
                        <p>Это легенда не только по архангельским, но и по общероссийским меркам. Трудно найти в России
                            гастролирующего музыканта, не знающего про это место. На сцене "Колеса" бывали и
                            "Крематорий", и "Ном", и музыканты из "Animal ДжаZ", а также группы со всего мира - от
                            Японии до США, от Австралии до Канады. Что уж говорить о ближайших скандинавских соседях?..
                            Недаром стены клуба сплошь украшены автомобильными номерами со всех уголков планеты. </p>
                    </Grid>
                    <Grid item xs={12} md={6} className='centered'>
                        <Poster
                            appImage={true}
                            imageSrc={'assets/images/about/koleso_about_2.png'}
                        />
                    </Grid>
                    <Grid item xs={12} className='bordered'/>
                    <Grid item xs={12} md={6}>
                        <h3>Музыка на любой вкус</h3>
                        <p>"Колесо" славится своей атмосферой и демократичностью. Здесь находят для себя интересное и
                            более взрослые поклонники музыки (блюз, джаз, классический рок), и молодые любители
                            современных направлений (гаражный рок, пост-рок, панк-рок, шугейз, хип-хоп и др.). Широко
                            представлена и ночная сцена: примерно раз-два в неделю проходят вечеринки с музыкой
                            различных стилей: рок, в том числе и русский, инди, фанк, панк, музыка 80-х, 90-х, 00-х и
                            др.</p>
                    </Grid>
                    <Grid item xs={12} md={6} className='centered'>
                        <Poster
                            appImage={true}
                            imageSrc={'assets/images/about/koleso_about_3.png'}
                        />
                    </Grid>
                    <Grid item xs={12} className='bordered'/>
                    <Grid item xs={12}>
                        <h3>Превосходная кухня и бар</h3>
                        <p>Главное - конечно, музыка, но и повара тоже не отстают. Здесь представлена европейская кухня.
                            Работает бар.</p>
                        <p>Цена пенных напитков начинается от 200 р. за 0.5 л.</p>
                        <p>Пенное - в ассортименте: бельгийское, немецкое, белорусское, разнообразный крафт и
                            другое.</p>
                        <p>Для клиентов предпочтительно бронировать места заранее на интересующие их мероприятия.</p>
                    </Grid>
                    <Grid item xs={12} md={6} className='centered'>
                        <Poster
                            appImage={true}
                            imageSrc={'assets/images/about/koleso_about_4.png'}
                            //maxHeight={180}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} className='centered'>
                        <Poster
                            appImage={true}
                            imageSrc={'assets/images/about/koleso_about_5.png'}
                            //maxHeight={180}
                        />
                    </Grid>
                </Grid>
            </ContainerTitled>
        </Layout>
    )
}

export default AboutPage