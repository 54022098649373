import React from "react";
import Logo from "../misc/Logo";
import {Box, Container, createStyles, Grid, makeStyles, Theme} from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            maxWidth: 1440,
            margin: ' 0 auto auto auto',
            padding: '45px 20px 0 20px',
            minHeight: '100%',
            fontSize: 10,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            [theme.breakpoints.only('xs')]: {
                textAlign: 'center',
                padding: '40px 20px 20px 20px',
            }
        },
        logo:{
            //marginBottom: 20
        },
        copyright:{
            [theme.breakpoints.only('xs')]: {
                padding: 20,
                marginBottom: 20
            }
        }
    }),
)

interface FooterProps {
    className?: string
}

export const Footer = (props: FooterProps) => {
    const classes = useStyles()
    const year = new Date().getFullYear()
    return (
        <Box className={props.className}>
            <Container className={classes.container}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={4} md={3} lg={2} className={classes.logo}>
                        <Logo size={220}/>
                    </Grid>
                    <Grid item xs={12} sm={8} md={4} container className={classes.copyright}>
                        <Grid item xs={12}>
                            © {year}, Колесо
                        </Grid>
                        <Grid item xs={12}>
                            Все права защищены. Все текстовые, графические и фото материалы данного сайта
                            могут использоваться только с разрешения администрации.<br />
                            <a href={'/files/Музыка_ПОЛИТИКА_КОНФИДЕНЦИАЛЬНОСТИ.pdf'} target={'_blank'} rel={'noreferrer'}>Политика конфиденциальности</a>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}
export default Footer