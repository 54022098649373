import {createStyles, makeStyles, Typography} from '@material-ui/core'
import Box from '@material-ui/core/Box'
import React, {useEffect} from 'react'
import {useLocation} from 'react-router-dom'
import ym from 'react-yandex-metrika'
import ContactBlock from '../components/basics/ContactBlock'
import Layout from '../components/layout/Layout'
import {Helmet} from 'react-helmet-async'
import ContainerTitled from '../components/misc/ContainerTitled'
import SimpleGalleryList from '../components/misc/Gallery/SimpleGalleryList'

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            background: 'url(/assets/images/misc/border-h.svg) repeat-x left bottom',
            backgroundSize: '16px',
            padding: '30px 0',
            fontWeight: 500,
            '&:first-child': {
                paddingTop: 0
            },
            '& h3': {
                fontSize: 24,
                fontFamily: 'Zantroke',
                paddingLeft: 20
            },
            '& ul': {
                paddingLeft: 20,
                '& li':{
                padding: '5px 20px 5px 0',
                //fontSize: 21
            }
        }
        }
    }),
)

export const RiderPage = () => {
    const {pathname} = useLocation()
    const classes = useStyles()

    useEffect(() => {
        ym('hit', pathname)
    }, [])

    return (
        <Layout title={'Звуковой райдер'} additionalComponents={[
            <ContactBlock key={'contact_block'}/>,
            <SimpleGalleryList key={'simple_gallery'} title={'Последние концерты'} maxWidth={280} last/>
            ]}>
            <Helmet>
                <title>Колесо: Звуковой райдер</title>
            </Helmet>
            <ContainerTitled>
                <Box className={classes.root}>
                    <Typography variant={'h3'}>Акустические системы</Typography>
                    <ul>
                        <li>PA – Martin Audio</li>
                        <li>Мониторы – JBL VRX 915m 15'' (2), JBL JRX 112m (2), IEM Sennheizer G4</li>
                        <li>Усилители Crest Audio, Lab Gruppen (достаточной мощности для нашего помещения)</li>
                    </ul>
                </Box>
                <Box className={classes.root}>
                    <Typography variant={'h3'}>FOH</Typography>
                    <ul>
                        <li>Пульт Behringer X32 Digital Mixing Console</li>
                        <li>Behringer S32 сценический блок AES50</li>
                    </ul>
                </Box>
                <Box className={classes.root}>
                    <Typography variant={'h3'}>Backline</Typography>
                    <ul>
                        <li>Микрофоны Shure 58, drum kit set (Shure PG 56, Shure PG 81, Shure 52, Shure Beta 52), Audio-Technica ATM650, Sennheizer E609, радиомикрофон Sennheizer EW100 G4 - 945S</li>
                        <li>Барабаны Tama Swingstar (12`, 13`, 16`, 3 стойки под тарелки)</li>
                        <li>Басовый комбо Ashdown MAG 300 (DI выход)</li>
                        <li>Гитарный комбо (1) Marshall TSL 601 (JCM 2000) с напольным контроллером</li>
                        <li>Гитарный комбо HIWATT MAXWATT G100</li>
                        <li>Гитарный комбо (2) Flag Custom Tube Guitar Amplifier</li>
                    </ul>
                </Box>
                <p style={{fontWeight:500}}>
                    По всем вопросам писать сюда: <a href={'mailto:palsanych@rambler.ru'}>palsanych@rambler.ru</a>
                </p>
            </ContainerTitled>
        </Layout>
    )
}

export default RiderPage