import {Box, Button, Grid, Typography} from '@material-ui/core'
import React, {useEffect, useState} from 'react'

const Terms = () => {
  const [show, setShow] = useState(false)

  useEffect(() => {
    const accepted = localStorage.getItem('termsAccepted')
    if (!accepted) {
      setShow(true)
    }
  }, [])

  const handleClick = () => {
    localStorage.setItem('termsAccepted', '1')
    setShow(false)
  }

  if (!show) return null

  return (
    <Box style={{
      width: '100%',
      position: 'fixed',
      bottom: 0,
      padding: '20px',
      background: '#fff',
      zIndex: 1000,
      boxSizing: 'border-box',
      borderTop: '1px dashed #000'
    }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={8} md={9} lg={10}>
          <Typography style={{flexShrink: 2}}>Этот сайт использует файлы cookies и сервисы сбора технических данных
            посетителей Яндекс.Метрика и Top.Mail.Ru (данные об IP-адресе, местоположении и др.) для обеспечения
            работоспособности и улучшения качества обслуживания. Продолжая использовать наш сайт, вы автоматически
            соглашаетесь с использованием данных технологий.
          </Typography>
        </Grid>
        <Grid container item xs={12} sm={4} md={3} lg={2} style={{alignItems: 'center'}}>
          <Grid item xs={6} sm={12} style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
            <a href={'/files/Музыка_ПОЛИТИКА_КОНФИДЕНЦИАЛЬНОСТИ.pdf'} target={'_blank'} rel={'noreferrer'}>Политика
              конфиденциальности</a>
          </Grid>
          <Grid item xs={6} sm={12} style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
            <Button disableElevation onClick={handleClick} variant={'contained'} color={'primary'}
                    style={{height: 42, width: 238, maxWidth: 238, marginTop: 10}}>Согласен</Button>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Terms
